<template>
  <div class="bg-main">
    <header class="absolute inset-x-0 top-0 z-50">
      <nav
        class="flex items-center justify-between p-8 lg:px-14 sm:px-9"
        aria-label="Global"
      >
        <div class="flex lg:flex-1 sm:flex-1">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Your Company</span>
            <img class="h-5 w-auto" src="~/assets/img/logo.png" alt="Logo" />
          </a>
        </div>
        <div class="flex lg:hidden sm:hidden">
          <button
            type="button"
            class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = true"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-8 w-8 text-white" aria-hidden="true" />
          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-12 sm:flex sm:gap-x-12">
          <a
            v-for="item in navigation"
            :key="item.name"
            :href="item.href"
            class="text-md font-semibold leading-6 text-white font-clash-display"
            >{{ item.name }}</a
          >
        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end sm:flex sm:flex-1 sm:justify-end">
          <a
            href="#"
            class="rounded-full px-3 py-1 text-md text-white ring-1 ring-white/50 hover:ring-white/40"
            >Contact Us <span aria-hidden="true">&rarr;</span></a
          >
        </div>
      </nav>
      <Dialog
        class="lg:hidden"
        @close="mobileMenuOpen = false"
        :open="mobileMenuOpen"
      >
        <div class="fixed inset-0 z-50" />
        <DialogPanel
          class="bg-main fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
        >
          <div class="flex items-center justify-between">
            <a href="#" class="-m-1 p-1">
              <span class="sr-only">Your Company</span>
              <img class="h-6 w-auto" src="~/assets/img/logo.png" alt="Logo" />
            </a>
            <button
              type="button"
              class="-m-2.5 rounded-md p-2.5 text-gray-700"
              @click="mobileMenuOpen = false"
            >
              <span class="sr-only">Close menu</span>
              <XMarkIcon class="h-8 w-8 text-white" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-white-500/10">
              <div class="space-y-4 py-6">
                <a
                  v-for="item in navigation"
                  :key="item.name"
                  :href="item.href"
                  class="-mx-1 text-[1.2rem] text-white block rounded-lg px-3 py-2 font-semibold leading-7 text-black hover:bg-gray-50 hover:text-black hover:text-[1.1rem] hover:px-3"
                  >{{ item.name }}</a
                >
                <a
                  href="#"
                  class="-mx-1 text-[1.2rem] text-white block rounded-lg px-3 py-2 font-semibold leading-7 text-black hover:bg-gray-50 hover:text-black hover:text-[1.1rem] hover:px-3"
                  >Contact Us</a
                >
              </div>

            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>

    <div class="relative isolate px-8 pt-14 lg:px-14">
      <div class="me-auto max-w-10xl pt-20 lg:pt-20 lg:pb-10">
        <div class="text-left">
          <div class="flex gap-5">
            <h1
              class="text-4xl mb-5 font-bold text-white sm:text-7xl lg:text-8xl font-clash-display"
            >
              WE CREATE
            </h1>
            <img
              src="~/assets/img/banner.png"
              alt="Banner"
              class="w-auto h-15 hidden lg:block"
            />
          </div>
          <h1
            class="text-3xl font-bold text-white sm:text-6xl lg:text-8xl font-clash-display sm:mt-2"
          >
            CREATIVITY WITH BRILIANT IDEAS
          </h1>
          <p class="text-white text-1xl sm:text-[1.6rem] lg:text-[2.4rem] font-jakartaplus mt-5">
            Creative solutions, we help provide solutions to help your business grow in a modern way.
          </p>
          <div class="mt-7 flex gap-x-6">
            <a
              href="#"
              class="flex gap-3 shadow-lg shadow-black rounded-full bg-yellow-600 px-3.5 py-2.5 text-sm font-bold font-clash-display text-black shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
              >
              <p class="mt-1">LET'S TALK</p>
              <img src="~/assets/img/icon/arrow-circle.png" class="w-auto h-7" alt=""> 
            </a>
          </div>
          <div class="mt-5 flex gap-x-6">
            <a
              href="#"
              class="flex gap-2 px-2 sm:px-3.5 py-2.5 text-sm text-[1.5rem] font-clash-display text-white mb-5"
              >
              <p>Let's scroll down again</p>
              <img src="~/assets/img/icon/arrow-down-circle.png" class="w-auto h-5" alt=""> 
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Dialog, DialogPanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";

const navigation = [
  { name: "Home", href: "#" },
  { name: "Service", href: "#" },
  { name: "Works", href: "#" },
  { name: "Store", href: "#" }
];

const mobileMenuOpen = ref(false);
</script>
