<template>
  <div class="bg-black">
    <div>
      <div class="me-auto max-w-10xl lg:pt-10 lg:pb-10">
        <div class="text-left">
          <h1
            class="text-[1.2rem] sm:text-3xl lg:text-4xl font-bold text-white sm:text-5xl font-clash-display px-9 lg:px-14 pt-20"
          >
            THE SECRET OF GETTING <br />
            AHEAD IS <span class="text-yellow-500"> GETTING STARTED.</span>
          </h1>
        </div>
        <div class="lg:px-14 lg:pt-14 pt-10 px-9">
          <div>
            <div class="divide-y border-accordion">
              <details class="group" open>
                <summary
                  class="flex cursor-pointer list-none items-center justify-between py-4 text-white font-clash-display font-bold text-[1.3rem]"
                >
                  Understanding Briefings
                  <div class="text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="block h-6 w-6 transition-all duration-300 group-open:rotate-180 rounded-full border border-gray-500">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  </div>
                </summary>
                <div class="pb-4 text-white font-clash-display text-[1rem]">
                  First we will understand the brief that has been given by the
                  client to our team and after that deal for the price.
                </div>
              </details>
              <details class="group" open>
                <summary
                  class="flex cursor-pointer list-none items-center justify-between py-4 text-white font-clash-display font-bold text-[1.3rem]"
                >
                  Brainstorming
                  <div class="text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="block h-6 w-6 transition-all duration-300 group-open:rotate-180 rounded-full border border-gray-500">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  </div>
                </summary>
                <div class="pb-4 text-white font-clash-display text-[1rem]">
                  Next, we determine the planing and structure to start working
                  on the project before entering into the design process.
                </div>
              </details>
              <details class="group" open>
                <summary
                  class="flex cursor-pointer list-none items-center justify-between py-4 text-white font-clash-display font-bold text-[1.3rem]"
                >
                  Design Process
                  <div class="text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="block h-6 w-6 transition-all duration-300 group-open:rotate-180 rounded-full border border-gray-500">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  </div>
                </summary>
                <div class="pb-4 text-white font-clash-display text-[1rem]">
                  After brainstorming and then we will make low fidelity to get
                  approval first before going to the next step.
                </div>
              </details>
              <details class="group" open>
                <summary
                  class="flex cursor-pointer list-none items-center justify-between py-4 text-white font-clash-display font-bold text-[1.3rem]"
                >
                  Development
                  <div class="text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="block h-6 w-6 transition-all duration-300 group-open:rotate-180 rounded-full border border-gray-500">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  </div>
                </summary>
                <div class="pb-4 text-white font-clash-display text-[1rem]">
                  When the process design is complete, our website department
                  team will do the next work before we hand it over to the
                  client for further processing.
                </div>
              </details>
              <details class="group" open>
                <summary
                  class="flex cursor-pointer list-none items-center justify-between py-4 text-white font-clash-display font-bold text-[1.3rem]"
                >
                  Deliver and Payment
                  <div class="text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="block h-6 w-6 transition-all duration-300 group-open:rotate-180 rounded-full border border-gray-500">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  </div>
                </summary>
                <div class="pb-4 text-white font-clash-display text-[1rem]">
                  When the Hifi process and development have been completed. We
                  will convey to the client for further payment.
                </div>
              </details>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Work"
};
</script>
