<template>
  <div class="bg-black">
    <div>
      <div class="me-auto max-w-10xl lg:pt-10 lg:pb-10 pt-10">
        <div class="text-left">
          <h1
            class="text-[1.2rem] sm:text-3xl lg:text-4xl font-bold text-white sm:text-5xl font-clash-display px-9 lg:px-14"
          >
            CREATIVITY IS INTELLIGENCE <br />
            <span class="text-yellow-500"> HAVING FUN.</span>
          </h1>
          <p class="text-white font-semibold font-clash-display lg:px-14 px-9 sm:text-[1.1rem] lg:text-[1.2rem] text-[0.8rem]">
            Good design is all about making other designers feel like idiots because that idea wasn't theirs.
          </p>
          <Swiper
            class="sm:mt-20 mt-10"
            :modules="modules"
            :slides-per-view="2.5"
            :space-between="spaceBetween"
            :pagination="{ clickable: true }"
            :scrollbar="{ draggable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :breakpoints="{
              640: {
                spaceBetween: 20, // Jarak antara slide pada layar >= 640px
                slidesPerView: 2.5
              },
              768: {
                spaceBetween: 20, // Jarak antara slide pada layar >= 768px
                slidesPerView: 2.5
              },
              1024: {
                spaceBetween: -90, // Jarak antara slide pada layar >= 1024px
                slidesPerView: 2.5
              }
            }"
          >
            <SwiperSlide class="lg:px-14 ml-9 sm:ml-0">
              <div class="card">
                <img src="~/assets/img/service/service1.png" alt="Gambar" class="card-img">
                <div class="card-content">
                  <p class="text-white text-semibold font-clash-display sm:text-[1.2rem] text-[1rem]">2021</p>
                  <h2 class="text-white text-bold font-clash-display sm:text-[1.5rem] text-[0.9rem]  mr-14">Sell and Buy <br> E-Commerce</h2>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide class="lg:px-14 ">
              <div class="card">
                <img src="~/assets/img/service/service2.png" alt="Gambar" class="card-img">
                <div class="card-content">
                  <p class="text-white text-semibold font-clash-display sm:text-[1.2rem] text-[1rem]">2021</p>
                  <h2 class="text-white text-bold font-clash-display sm:text-[1.5rem] text-[0.9rem] mr-14">Website <br> E-Commerce</h2>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide class="lg:px-14 ">
              <div class="card">
                <img src="~/assets/img/service/service3.png" alt="Gambar" class="card-img">
                <div class="card-content">
                  <p class="text-white text-semibold font-clash-display sm:text-[1.2rem] text-[1rem]">2021</p>
                  <h2 class="text-white text-bold font-clash-display sm:text-[1.5rem] text-[0.9rem] mr-14">Dashboard <br> Company</h2>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      slides: [
        { id: 1, image: "/path/to/image1.jpg", alt: "Image 1" },
        { id: 2, image: "/path/to/image2.jpg", alt: "Image 2" }
        // Add more slides as needed
      ],
      spaceBetween: 20,
    };
  }
};
</script>
