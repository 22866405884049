<template>
  <div class="bg-black">
    <div>
      <div class="mx-auto max-w-4xl sm:pt-14 sm:pb-14">
        <div class="text-center">
          <h1
            class="text-[1.2rem] sm:text-4xl font-bold text-white sm:text-5xl font-clash-display px-9 lg:px-14 pt-20"
          >
            Make the ordinary into extraordinary things with us.
          </h1>
        </div>
        <div class="mt-10 flex justify-center gap-x-6">
          <a
            href="#"
            class="flex gap-3 shadow-lg shadow-indigo-800 rounded-full bg-yellow-600 px-3.5 py-2.5 text-sm font-bold font-clash-display text-indigo-800 shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-800"
          >
            <p class="mt-1">LET'S TALK</p>
            <img
              src="~/assets/img/icon/arrow-right-indigo.png"
              class="w-auto h-7"
              alt=""
            />
          </a>
        </div>
        <div class="text-center mt-14">
          <div class="flex justify-center gap-3">
            <p
              class="text-[1.3rem] text-white sm:text-2xl font-clash-display lg:px-14 px-9"
            >
              Instagram
            </p>
            <p
              class="text-[1.3rem] text-white sm:text-2xl font-clash-display lg:px-14 px-9"
            >
              Likedln
            </p>
          </div>
        </div>
        <div class="text-center mt-10">
          <div class="flex justify-center gap-1">
            <p
              class="text-[1.2rem] text-white sm:text-2xl font-clash-display lg:px-6 px-1"
            >
              Build with ❤ TimOpen.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Store"
};
</script>
