<template>
  <Header />
  <DigitalExperience />
  <CreativeIntelligence />
  <GettingStarted />
  <Footer />
</template>

<script>
import Header from "./components/Header.vue";
import DigitalExperience from "./components/DigitalExperience";
import CreativeIntelligence from "./components/CreativeIntelligence";
import GettingStarted from "./components/GettingStarted";
import Footer from "./components/Footer";
import { defineComponent, ref, onMounted, computed } from "vue";

export default defineComponent({
  components: {
    Header,
    DigitalExperience,
    CreativeIntelligence,
    GettingStarted,
    Footer
  }
});
</script>
