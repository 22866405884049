<template>
  <div class="bg-black">
    <div>
      <div class="me-auto max-w-10xl lg:pt-10 lg:pb-10">
        <div class="text-left">
          <
          <h1
            class="text-[1.2rem] sm:text-3xl lg:text-4xl font-bold text-white sm:text-5xl font-clash-display px-9 lg:px-14"
          >
            CRAFTING DIGITAL EXPERIENCES <br />
            THAT <span class="text-yellow-500"> INSPIRE AND CONNECT.</span>
          </h1>
          <div class="border-t border-color border-solid my-10"></div>
          <ul
            role="list"
            class="grid gap-x-8 gap-y-12 lg:grid-cols-2 sm:gap-y-16 xl:col-span-2"
          >
            <li class="lg:px-14 px-7">
              <div class="flex items-center gap-x-3 card-product">
                <img src="~/assets/img/product/uiux.png"  alt="UI/UX" />
                <div class="mb-5">
                  <h3
                    class="text-[1rem] sm:text-[2.2rem] font-bold font-clash-display text-white"
                  >
                    UIUX Design
                  </h3>
                  <p
                    class="text-[0.65rem] sm:text-[1.2rem] font-semibold font-clash-display text-white"
                  >
                    Create a look for your website or mobile application to your
                    liking with a modern and contemporary style.
                  </p>
                </div>
              </div>
            </li>
            <li class="lg:px-14 px-7">
              <div class="flex items-center gap-x-3 card-product">
                <img src="~/assets/img/product/webdes.png" alt="Web Design" />
                <div class="mb-5">
                  <h3
                    class="text-[1rem] sm:text-[2.2rem] font-bold font-clash-display text-white"
                  >
                    Web Design
                  </h3>
                  <p
                    class="text-[0.65rem] sm:text-[1.2rem] font-semibold font-clash-display text-white"
                  >
                    We help to create and develop website design that will help
                    your business to grow bigger.
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <div class="lg:mx-auto lg:max-w-2xl lg:pt-20 lg:pb-20 lg:px-5 px-10 pt-10 pb-10">
            <div class="flex items-center gap-y-3 lg:px-14 card-product">
              <img src="~/assets/img/product/webdev.png" alt="Web Development" />
              <div>
                <h3 class="text-[1rem] sm:text-[2.2rem] font-bold font-clash-display text-white">
                  Website Development
                </h3>
                <p
                  class="text-[0.65rem] sm:text-[1.2rem] font-semibold font-clash-display text-white"
                >
                    We help to create and develop website design that will help your business to grow bigger.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="border-t border-color border-solid"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Dialog, DialogPanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";

const navigation = [
  { name: "Home", href: "#" },
  { name: "Product", href: "#" },
  { name: "Service", href: "#" },
  { name: "Works", href: "#" },
  { name: "Store", href: "#" }
];

const mobileMenuOpen = ref(false);
</script>
